
.header {

    &__container {
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
    }

    &__logo {
        @media(max-width:$breakpoint480){
            width: 230px;
            height: 55px;
        }
    }

    &__nav {
    }
}
