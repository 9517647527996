:root {
	--container-width: 1200px;
	--container-padding: 15px;
	--hover-color: #C9FA49; 
	--font-main: 'Inter', sans-serif;
	--second-fam: 'Outfit', sans-serif;
	--second-bg-color: #e5efeb;
	// --font-accent: 'Playfair Display', serif;

	--page-bg: #FDFDFD;
	--text-color: #000;
	--accent: #ac182c;




}
$breakpoint1200: 1199.98px;
$breakpoint1450: 1449.98px;
$breakpoint480: 479.98px;
$breakpoint768: 767.98px;
$breakpoint992: 991.98px;
$breakpoint640: 639.98px;
.dark {
    --page-bg: #252526;
    --text-color: #fff;
}

