/* Base */
@import "./base/reset";
@import "./base/vars";
@import "./base/base";
@import "./base/utils";
@import "./base/containers";
@import "./base/sticky-footer";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@700&display=swap');

/* Blocks */
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "blocks/_logo.scss";
@import "blocks/_mobile-nav.scss";
@import "blocks/_nav-icon.scss";

html, body {
	scroll-behavior: smooth;
	
background: #161730;
color:#fff;

 }
 body{
	
	background-image: url(../img/body-grad.svg);
	background-position: (top left);
	background-repeat: no-repeat;
	background-size: auto;
	@media(max-width:$breakpoint768){
		background-image: none;
	}
 }
 h1, h2 ,h3{
	span{
		background: linear-gradient(45deg, #351A88 25%, #FF6654 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
 } 
[class$=__container]{
	max-width: 1210px;
	padding: 0 15px;
	margin: 0 auto;
}
h2{


@media(max-width:$breakpoint768){
	
}
@media(max-width:$breakpoint480){

}
}
h3{



@media(max-width:$breakpoint768){

}
@media(max-width:$breakpoint480){

}
}



.hero {
	padding: 120px 0;
	@media(max-width:$breakpoint768){
		padding: 50px 0;
	}
	@media(max-width:$breakpoint480){padding: 25px 0;}
	&__container {

	}

	&__content {
		display: flex;
		gap: 39px;
		align-items: center;
		@media(max-width:$breakpoint768){
			justify-content: center;
		}
	}

	&__form {
		max-width: 699px;
		@media(max-width:$breakpoint768){
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&__title {
		font-size: 55px;
		line-height: 150%;
		font-weight: 700;
		@media(max-width:$breakpoint1200){font-size: 48px;}
		@media(max-width:$breakpoint768){font-size: 40px;}
		@media(max-width:$breakpoint480){font-size: 30px;
		text-align: center;}
	}

	&__img {
		@media(max-width:$breakpoint1200){
			display: none;
		}
	}
}
.consult-form {
	margin: 24px 0px 0px 0px;
	display: flex;
	flex-wrap: wrap;
	gap:30px;
	@media(max-width:$breakpoint768){
		flex-direction: column;
		align-items: center;
	}

	input{
		max-width: 260px;
		width: 100%;
		height: 50px;
		padding: 0 15px;
		border: 1px solid #8a6fdb;
	}
}
.consult-btn {
	max-width: 260px;
	width: 100%;
	height: 50px;
	background-color: #351A88;
	transition: all .3s ease;
	&:hover{
		background-color: #714ae7;
	}
}
.messages {
	color: red;
}
.true {
	padding:  50px 0;
	&__container {
	}

	&__title {
		font-weight: 600;
		font-size: 48px;
		text-align: center;
		@media(max-width:$breakpoint768){
			font-size: 40px;
		}
		@media(max-width:$breakpoint480){font-size: 25px; text-align: left;}
	}

	&__text {
		margin: 30px 0px 0px 0px;
		font-size: 20px;
		color: #a5a3a8;
		text-align: center;
		@media(max-width:$breakpoint480){
			text-align: left;
		}
	}

	&__items {
		margin: 50px 0px 0px 0px;
		display: flex;
		gap: 30px;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__item {
	}
}
.about {
	padding: 100px 0;
	@media(max-width:$breakpoint768){
		padding: 50px 0;
	}
	@media(max-width:$breakpoint480){
		padding: 25px 0;
	}

	&__container {
		display: flex;
		align-items: start;
		gap: 56px;
		
	}

	&__content {
		
	}

	&__title {
		font-size: 40px;
		font-weight: 600;
		@media(max-width:$breakpoint768){
			font-size: 30px;
		}
		@media(max-width:$breakpoint480){font-size: 25px;}
	}

	&__text {
		margin: 20px 0px 0px 0px;
		font-size: 20px;
		color: #a5a3a8;
		@media(max-width:$breakpoint480){font-size: 16px;}
	}

	&__img {
		@media(max-width:$breakpoint1200){
			display: none;
		}
	}
}
.why {
	padding: 50px 0;
	&__container {
	}

	&__top {
		display: flex;
		gap: 20px;
		align-items: start;
	}

	&__img {
		@media(max-width:$breakpoint1200){
			display: none;
		}
	}

	&__content {
	}

	&__title {
		font-size: 40px;
		font-weight: 600;
		@media(max-width:$breakpoint768){
			font-size: 30px;
		}
		@media(max-width:$breakpoint480){font-size: 25px;}
	}

	&__text {
		margin: 20px 0px 0px 0px;
		font-size: 20px;
		color: #a5a3a8;
		@media(max-width:$breakpoint480){font-size: 16px;}
	}
}
.make {
	padding: 50px 0;
	&__container {
		text-align: center;
		@media(max-width:$breakpoint480){
			text-align: left;
		}
	}

	&__title {
		font-size: 48px;
		font-weight: 600;
		@media(max-width:$breakpoint768){
			font-size: 30px;
		}
		@media(max-width:$breakpoint480){font-size: 25px;}
	}

	&__text {
		margin: 40px 0px 0px 0px;
		font-size: 20px;
		color: #a5a3a8;
		@media(max-width:$breakpoint480){font-size: 16px;}
	}
}


.revs {
	padding: 50px 0;
	position: relative;
	background-image: url(../img/revs-bg.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	&__container {
	}

	&__title {
		margin: 0px 0px 30px 0px;
		text-align: center;
		font-weight: 700;
		font-size: 50px;
		line-height: 100%;
		color: #fff;
		@media(max-width:$breakpoint768){font-size: 40px;}
		@media(max-width:$breakpoint480){font-size: 30px;}
	}

	&__slider {
		margin: 30px 0px 0px 0px;
	}
}
.slider {
	
	&__item {
		padding: 0 50px;
		display: flex!important;
		flex-direction: column;
		align-items: center;
		@media(max-width:$breakpoint480){
			padding: 0 15px;
		}
		
	}
	&__top{
		display: flex;
		gap:25px;
		align-items: center;
	}
	&__wrap{
	
		display: flex;
		flex-direction: column;
		gap:10px;
	
		max-width: 1000px;
		width: 100%;
		padding: 50px 120px;
		@media(max-width:$breakpoint1200){
			padding: 30px 50px;
			max-width: 700px;
		}
		@media(max-width:$breakpoint992){
			max-width: 500px;
			flex-direction: column;
			align-items: center;
		
		}
		@media(max-width:$breakpoint768){
			padding: 20px;
			max-width: 400px;
		}
	}
	&__text {
		margin: 20px 0px 0px 0px;
		font-weight: 400;
font-size: 18px;
letter-spacing: 0.02em;


max-width: 633px;
border-radius: 50px;
@media(max-width:$breakpoint480){
	text-align: start;
	padding: 0;
	border: none;
	font-size: 16px;
}
color: #a5a3a8;
	
		
	}

	&__bottom {
		margin: 30px 0px 0px 0px;
		@media(max-width:$breakpoint480){
			margin: 20px 0px 0px 0px;
		}
		display: flex;
		align-items: center;
		gap:25px;
		justify-content: center;
	}

	&__icon {
	}

	&__name {
		font-weight: 400;
		font-size: 24px;
		line-height: 117%;
		color: #fff;
		@media(max-width:$breakpoint480){
			font-size: 24px;
			text-align: start;
			align-self: flex-start;
		}
		margin: 20px 0px 0px 0px;

	}
}

/* Стрелка */
.slick-arrow{
	
}

/* Стрелка не активная */
.slick-arrow.slick-disabled{}

.slick-dots{
	display: flex;
	gap:10px;
	margin: 5px 0px 0px 0px;
	justify-content: center;
}

.slick-dots li{
	font-size: 0;
	width: 57px;
	height: 16px;
	border-radius: 8px;
	background: #d9d9d9;
	cursor: pointer;
	transition: .2s linear;

}

/* Активна точка */
.slick-dots li.slick-active{
	background: #a83e3e;
}

/* Елемент точки */
.slick-dots li button{}


 
