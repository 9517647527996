.footer {
	padding: 50px 0;
	

	color: #fff;
	background: #161730;
	a{
		color: #fff;
		transition: all .3s ease;
		&:hover{
			color:#FF6654;
		}
	}
}

.footer {

	&__container {
		border-top: 2px solid silver;
		&:nth-child(2){
			border-top: none;
		}
		padding-top: 20px;
		display: flex;
		gap: 50px;
	
		 justify-content: space-between;
		 @media(max-width:$breakpoint768){
			flex-direction: column;
			align-items: center;
		 }
	}

	



	&__right{
	
	
	}
	&__nav{

		margin: 30px 0px 0px 0px;
		display: flex;
		flex-direction: column;
		
		gap: 30px;
		@media(max-width:$breakpoint768){
			text-align: center;
		}
		@media(max-width:$breakpoint480){
			padding: 30px 0 ;
			border-top: 1px solid white;
			border-bottom: 1px solid white;
		}
		
	}






	&__text {
		font-weight: 400;
font-size: 18px;
line-height: 194%;
color: #fff;
		@media(max-width:$breakpoint768){
			font-size: 16px;
			order: 1;
		}
	}


}

